import { GetPublisherConfigurationDTO, PutPublisherConfigurationDTO } from "models/ssp/publisher.dto";
import { IPublisherService } from "./ipublisher-service";
import { ConnectedService } from "services/connected-service";

type ConnectedGetPublisherConfigurationDTO = {
  data: {
    dataValues: GetPublisherConfigurationDTO
  }
}

export class ConnectedPublisherService extends ConnectedService implements IPublisherService  {
  async getConfiguration(siteId: string): Promise<GetPublisherConfigurationDTO | null> {
    const result = await this._get<ConnectedGetPublisherConfigurationDTO>(`publisher-configuration/${siteId}`);
    return result.data.dataValues;
  }

  async saveConfiguration(siteId: string, config: PutPublisherConfigurationDTO): Promise<void> {
    return await this._put(`publisher-configuration`, config);
  }
}